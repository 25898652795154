@import "../../../variables";

#eLiveContainer {
  .videoContainer {
    width: 100%;
    height: 600px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .trainingReport {
    display: flex;
    justify-content: flex-end;
    .section {
      &:first-child {
        margin-right: 50px !important ;
      }
      :first-child {
        flex-grow: 0;
      }
      width: 70%;
      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;
      .tableHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 75px 0px 30px;
      }
      .Training-List-item {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: space-between;
        padding: 0px 60px 15px 30px;
        :first-child {
          width: 70%;
        }
        &.bold {
          font-weight: bold;
          padding: 15px 60px 0px 30px;
          line-height: 0;
        }
        .statusBadge .badge {
          width: unset !important;
          :first-child {
            width: 80px;
          }
        }
      }
      .colorDivider {
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
    }
  }
  .recCourses {
    display: flex;
    flex-direction: column;
    .section {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0 !important;
      // &:first-child {
      //   margin-right: 50px !important ;
      // }
      :first-child {
        flex-grow: 0;
      }
      // width: 70%;
      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;
      .heading2 {
        padding: 5px 30px;
      }
      .Course-List-item {
        text-align: center;
        font-weight: bold;
        // display: flex;
        // flex-direction: row;
        // text-align: left;
        // padding: 0px 60px 15px 30px;
        // :first-child {
        //   width: 70%;
        // }
        // &.bold {
        //   font-weight: bold;
        //   padding: 15px 60px 0px 30px;
        //   line-height: 0;
        // }
      }
      .colorDivider {
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
    }
  }
  .barChartDiv {
    height: auto;
    padding-bottom: 15px;
    .section{
      height: 380px;
      flex-direction: column;
    }
  }
  .lastActivity {
    color: $secondary;
    font-size: 1.3em;
    margin-bottom: 0.5em;
    align-items: center;
    font-weight: 600;
  }
  #iconTooltip{
    font-weight: bold;
  }
}

////// MOBILE STYLING
@media screen and (max-width: 767px) {
  #eLiveContainer {
    .trainingReport {
      display: inline-block !important;
      .section {
        width: 100%;
        .tableHeader {
          padding: 10px 0px;
        }
        .Training-List-item {
          padding: 10px 0px;
          .statusBadge {
            :first-child {
              min-width: 80px !important;
            }
          }
        }
      }
    }
    .recCourses {
      display: inline-block !important;
      .section {
        width: 100%;
      }
    }
  }
}
