@import "../../../variables";


#NewsDashContainer {
    .FeaturedSection{
        display: flex;
        flex-direction: column;
        width: unset;
        .section{
            :first-child{
                flex-grow: 0;
            }
            display: flex;
            flex-direction: column;
            padding: 20px 0px 20px 0px;
            .heading2{
                margin-bottom: 20px;
                padding: 5px 30px;
            }
            .imageItem{
                display: flex;
                flex-direction: row;
                text-align: left;
                padding: 15px 60px 20px 30px;
                .image-item-text{
                    .topText{
                        padding-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #NewsDashContainer {
        .FeaturedSection{
            width:80%;
            .heading1{
                font-size: 1.8rem;
            }
            .section{
                .imageItem{
                    width:80%;
                    display: table;
                    .image-item-text{
                        font-size: medium !important;
                        h3{
                            font-size: medium !important;
                        }
                    }
                }
            }
        }
    }
}