@import "../../../variables";

.weatherContainer {
  display: flex;
  justify-content: space-around;
  .section {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 2em !important;
    flex-grow: 0 !important;
    margin-right: 1em;
    height: auto;
    :first-child {
      flex-grow: 0 !important;
    }
    .pictureHeader {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;

      align-self: center;
      margin-bottom: 1em;
      max-height: 100px;
      div {
        width: auto;
        font-size: 4em;
        color: $tertiary;
        font-weight: bold;
      }
      svg {
        color: $tertiary;
      }
    }
    .weatherLabel {
      width: auto;
      font-size: 1.6em;
      color: $primary;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1.5em;
    }
    .weatherDescription {
      font-size: 1.1em;
      width: 100%;
      text-align: center;
    }
  }
}

.weatherLocation {
  color: $secondary;
  font-size: 1.3em;
  margin-bottom: 1em;
  align-items: center;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .weatherContainer {
    flex-direction: column;
    .section {
      width: 100%;
    }
  }
}
