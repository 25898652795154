#CstCBox {
    .inputCheckBox {
        width: 15px !important;
        height: 15px !important;
    }

    /*here custom*/
    /*Li Hack the checkbox to change the color and checkmark*/
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: relative;
        left: 0;
        opacity: 0.01;
    }

    [type="checkbox"]:not(:checked)+span,
    [type="checkbox"]:checked+span {
        position: relative;
        padding-left: 1.7em;
        line-height: 1.7;
        height: 15px;
        width: 15px;

    }

    /* Style its checked state...with a ticked icon */
    input[type=checkbox]:not(checked)+span:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        bottom: -2px;
        text-align: center;
        border: 1px solid darkgrey;
        height: 22px;
        width: 22px;
        border-radius: 3px;
        background-color: #124a80;
    }

    input[type=checkbox]:checked+span:before {
        content: '✔';
        position: absolute;
        top: -2px;
        left: 0;
        bottom: -2px;
        text-align: center;
        color: #f47e20;
        border: 1px solid darkgrey;
        height: 22px;
        width: 22px;
        border-radius: 3px;
    }

    /*Li Hack the checkbox to change the color and checkmark*/
}