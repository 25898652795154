@import "../../variables";

.LoginPageContent {
  width: 440px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  .titleHolder {
    width: 100%;
    h1 {
      text-align: left;
    }
  }
  .form {
    width: 100%;
    .form-control {
      width: 100%;
      .form-label {
        margin-bottom: 8px;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .input {
        width: 100%;
        font-size: 1.5rem;
        height: 2rem;
        border-radius: 4px;
        border: none;
        padding: 5px;
      }
    }
  }
  .button-container {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    margin: 1.5rem;
  }
  .roundButton {
    width: unset !important;
  }
  a {
    color: $white;
    font-weight: bold;
  }
}

// @media screen and (max-width: 767px) {
//   .sidebar {
//     height: calc(100% - 17vh);
//   }
// }
