@import "../../../variables";

#financialSummary {
  .headerContainer {
    display: flex;
    h1{
      margin-bottom: 1em;
    }
    :first-child {
      flex: 1;
    }
  }
  .renewalText {
    font-size: 2.5rem;
    .renewalDays {
      font-weight: 800;
    }
  }
  .summarySection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .section {
      // padding: 0 0 2rem 0 !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      .sectionTitles {
        padding:0;
        margin: 0;
        .heading1{
          font-size: 2em;
        }
      }
      .FPGridContainer{
        display: grid;
        grid-template-columns: 30% 70%;
        width:100%;
        .leftColumn{
          margin-right: 10%;
          .colorDivider{
            margin: .5rem 0;
          }
          .tableData {
            padding: 0 1.6rem;
            display: flex;
            span {
              padding-bottom: .4rem;
              font-size: 0.9em;
            }
          }
          .tableTitles {
            font-weight: 700;
            font-size: 1em;
            padding: 0 1.6rem;
            display: flex;
          }
        }
        .rightColumn{
          width: 95%;
          .graphHolder{
            min-height: 100px;
            div{
              min-height: 100px;
            }
          }
        }
      }
    }
  }
}

.statementContainer {
  .fileGroup {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .outerStatementHolder{
      display: flex;
      flex-direction: column;
      width: 100%;
      .innerStatementHolder{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .section {
          width: 20%;
          margin-right: 1.2rem;
          margin-top: 1.2rem;
          .statementCard {
            text-align: center;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            svg {
              margin-bottom: 10px;
            }
            a {
              font-weight: 700;
              font-size: 0.9rem;
              color: $secondary;
              text-decoration: none;
            }
            :last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
    
  }
}

.statementContainerMFA {
  .fileGroup {
    display: flex;
    justify-content: flex-start;
    .section {
      width: 20%;
      margin-right: 1.2rem;
      margin-top: 1.2rem;
      .statementCard {
        text-align: center;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        svg {
          margin-bottom: 10px;
        }
        a {
          font-weight: 700;
          font-size: 0.9rem;
          color: $secondary;
          text-decoration: none;
        }
        :last-child {
          margin-top: 10px;
        }
      }
    } 
  }
}

@media screen and (max-width: 767px) {
  #financialSummary {
    flex-direction: column;
    .summarySection {
      display: flex;
      flex-direction: column;
      .section {
        width: 100%;
        .FPGridContainer{
          display: grid;
          grid-template-columns: 100%;
          width:100%;
          .leftColumn{
            margin-right: 10%;
            .colorDivider{
              margin: .5rem 0;
            }
            .tableData {
              padding: 0 1.6rem;
              display: flex;
              span {
                padding-bottom: .4rem;
                font-size: 0.9em;
              }
            }
            .tableTitles {
              font-weight: 700;
              font-size: 1em;
              padding: 0 1.6rem;
              display: flex;
            }
          }
          .rightColumn{
            width: 95%;
            .graphHolder{
              min-height: 100px;
              div{
                min-height: 100px;
              }
            }
          }
        }
      }
    }
  }
  .statementContainer {
    .fileGroup {
      display: block;
      .section {
        width: 80%;
      }
    }
  }
}
