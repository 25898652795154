#insuranceDash {
  .headerContainer {
    display: flex;
    :first-child {
      flex: 1;
    }
  }
  .renewalText {
    font-size: 2.5em !important;
    .renewalDays {
      font-weight: 800;
    }
  }
  .insuranceSection {
    display: flex;
    justify-content: space-between;
    .section {
      padding: 0 0 2rem 0 !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      .sectionTitles {
        padding: 1.4rem 1.6rem;
        margin: 0;
      }
      .tableTitles {
        font-weight: 700;
        font-size: 1.3em;
        padding: 0 1.6rem;
        display: flex;
      }
      .tableData {
        padding: 0 1.6rem;
        display: flex;
        span {
          padding-bottom: 1rem;
          font-size: 1.1em;
        }
      }
      span {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #insuranceDash {
    .headerContainer,
    .insuranceSection {
      flex-direction: column;
    }
  }
}
