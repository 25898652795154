@import "../../../variables";

.notificationsButton {
//   border-left: 2px solid $secondary;
  padding: 20px 30px;
  width:210px;
  border-radius: 50px;
  background-color: $secondary;
  font-weight: 600;
  color:$white;
  font-size: 1.1rem;
  height: fit-content;
  margin: auto;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  .dot {
    height: 20px;
    width: 20px;
    background-color: $green;
    border-radius: 50%;
    float: left;
    // display: inline-block;
}
}


