@import "../../variables";

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#claimsReportContainer {
  .claimsHeader {
    display: flex !important;
    .buttonContainer {
      display: flex;
      flex-direction: column;
      .roundButton {
        width: unset !important;
      }
    }
    :first-child {
      flex-grow: 1 !important;
    }
  }
  .section:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    background-color: $tertiary !important;
    color: white;
    font-weight: bold;
    align-items: center;
    svg {
      margin-right: 1em;
      padding-left: 0.4em;
      width: unset !important;
    }
  }
  .section {
    //background-color: transparent;
    display: flex;
    flex-direction: column;
    .sectionHeader {
      //margin-left: -2%;
      display: flex;
      font-size: 26px;
      font-weight: bold;
      flex-wrap: wrap;
      .orangeNumber {
        color: $secondary;
        flex-grow: 0;
        margin-right: 0.5rem;
      }
      span{
        //font-size: 26px;
        font-weight: 500;
      }
    }
    .sectionContentWrapper {
      padding-left: 1.8rem;
      .boldText {
        font-weight: bold;
      }
      .gridWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .right-column {
          .displayNone {
            visibility: hidden;
          }
        }
      }
    }
  }
  .bottomLinksWrapper {
    font-weight: bold;
    align-items: center;
    .section:nth-of-type(2) {
      background-color: #f0f6fc !important;
      color: inherit;
      text-align: left;
      align-items: unset;
      svg {
        margin-right: 1em;
        padding-left: 0.4em;
      }
    }
    .section {
      display: flex;
      flex-direction: column !important;
      .textButton {
        font-size: 26px;
        border: 0;
      }
      .flex-rowHolder {
        display: flex;
        flex-direction: row;
      }
      .collapsible {
        position: relative;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: height 500ms, opacity 500ms;
        text-align: left;
        font-weight: normal;
        .textSection {
          padding-top: 0.5em;
        }
        //section
        .collapsible-section {
          height: 5vw;
          padding-top: 0.8rem;
          padding-bottom: 1.2rem;
          display: flex;
          line-height: 1.3;
          color: $white;
          flex-direction: column;
        }
      }
      // change width and opacity when toggled
      .open {
        height: fit-content;
        opacity: 1;
        * {
          // display:none;
          -webkit-animation: fadein 0.5s forwards; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 0.5s forwards; /* Firefox < 16 */
          animation: fadein 0.5s forwards;
        }
      }
      .closed {
        height: 0px;
        opacity: 1;
        * {
          // display:none;
          -webkit-animation: fadeout 0.3s forwards; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadeout 0.3s forwards; /* Firefox < 16 */
          animation: fadeout 0.3s forwards;
        }
      }
    }
  }
  .reportClaimIcons
    {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;
      padding-top: 15px !important;
      .section {
        //margin: auto !important;
        align-items: center;
        //margin-right: 5%;//120px;
        display: flex !important;
        flex-direction: column !important;
        background-color: #f0f6fc !important;
        color: #1d307c !important;
        padding: 2.5em !important;
        border-radius: 5px;
        .iconButton{
          padding-top: 10px;
          text-align: center;
        }
        a{
          //border-left: 2px solid #f47e20;
          padding-left: 4px;
          color: #f47e20;
          font-weight: 700;
          font-size: 1.1em;
          //height: -moz-fit-content;
          height: fit-content;
          line-height: 1rem;
          margin: auto;
          cursor: pointer;
        }
        .heading3{
          padding-top: 20px;
          font-weight: 800;
          color: #16204A;
        }
        button{
          //border-left: 2px solid #f47e20;
          //padding-top: 18px !important;
          margin-top: 25px !important;
          color: #f47e20;
          font-weight: 700;
          font-size: 1.1em;
          //height: -moz-fit-content;
          height: fit-content !important;
          line-height: 0.3rem !important;
          //margin: auto !important;
          cursor: pointer;
        }
      }
    }
}

@media screen and (max-width: 1280px) {
  #claimsReportContainer {
    font-size: 0.75em;
    .claimsHeader {
      display: inline-block !important;
    }
    .section:nth-of-type(2) {
      display: flex !important;
    }
    .section {
      //display: inline-block !important;
      
      display: flex !important;
      flex-direction: column !important;
      .sectionContentWrapper {
        .gridWrapper {
          display: inherit;
        }
      }
    }
    .reportClaimIcons
    {
      display: flex !important;
      flex-direction: column !important;
      padding-top: 15px !important;
      //padding-left: 1% !important;
      justify-content: space-between;
      width: 100%;
      
      .section {
        align-items: center;
        //margin-right: 1% !important;
        display: flex !important;
        flex-direction: column !important;
        background-color: #f0f6fc !important;
        color: #1d307c !important;
        //padding-left: 5% !important;
        //padding-right: 5% !important;
        width: 100%;

        .iconButton{
          padding-top: 10px;
          text-align: center;
        }
        a{
          //border-left: 2px solid #f47e20;
          padding-left: 14px;
          color: #f47e20;
          font-weight: 700;
          font-size: 1.1em;
          //height: -moz-fit-content;
          height: fit-content;
          line-height: 1rem;
          margin: auto;
          cursor: pointer;
        }
        .heading3{
          padding-top: 20px;
          font-weight: 800;
          color: #16204A;
        }
        button{
          //border-left: 2px solid #f47e20;
          //padding-top: 18px !important;
          margin-top: 25px !important;
          color: #f47e20;
          font-weight: 700;
          font-size: 1.1em;
          //height: -moz-fit-content;
          height: fit-content !important;
          line-height: 0.3rem !important;
          margin: auto !important;
          cursor: pointer;
        }
      }
    }
  }
}
