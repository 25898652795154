@import "../../variables";

#deleteDoc {
    .downloadIcon {

        //Li
        &:hover {
            color: $primary;
        }

        margin-left: 0.5rem !important;

    }

}