@import "../../variables";


#claimsContainer {
  .claimsHeader {
    display: flex !important;
    margin-bottom: 2rem;

    .leftSideContainer {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 2rem !important;
      }

      .ui.breadcrumb {
        display: flex;
        flex-direction: row;
        list-style: none;
        overflow: hidden;

        .slash {
          color: $secondary;
          font-weight: bolder !important;
        }

        #breadCrumbSection {
          flex-grow: 0 !important;
          margin: 0 !important;
          // margin-right: 1rem!important;
          font-weight: bolder;
          text-decoration: none;
          font-size: 8px;
          // padding: 10px 0 10px 10px !important;
          background: transparent;
          color: $primary;
          position: relative;
          display: block;
          float: left;
          text-align: center;

          .bigLabel {
            font-size: 16px;
          }

          &:hover {
            background: transparent;
            color: $tertiary;
          }

          &.active {
            background: transparent;
            color: $tertiary;
          }
        }

        #breadCrumbSection:nth-of-type(1) {
          margin-left: 0 !important;
        }
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;

      .roundButton {
        width: unset !important;
        flex: none !important;
      }

      .noPermButton {
        background-color: gray !important;
        opacity: 0.5;

      }
    }

    :first-child {
      flex-grow: 1 !important;
    }

  }

  // tabs section
  #claimsTabs {
    background: none !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    padding-bottom: 40px !important;

    :first-child {
      flex-grow: 0 !important;
    }

    .tab-content {
      display: flex !important;
      background-color: $lightBlue  !important;
      flex-direction: row !important;
      justify-content: space-between !important;

      .tab-pane {
        display: none !important;
      }

      .tab-pane.active {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        width: 100% !important;
        padding-bottom: 10px !important;

        li:first-of-type {
          padding-top: 45px !important;
        }

        li:last-of-type {
          padding-bottom: 60px !important;
        }

        .list-item {
          display: grid !important;
          grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
          // flex-direction: row !important;
          // justify-content: space-between !important;
          padding: 5px 20px !important;
          font-size: 1.1rem;
          border: none !important;

          .statusBadge .badge {
            width: unset !important;
            max-width: 125px !important;
          }
        }

        .id {
          min-width: 190px !important;
        }

        .textButton {
          margin: 0 !important;
          cursor: pointer !important;
        }
      }
    }

    .tabs {
      display: flex !important;
      padding-left: 0 !important;
      margin-bottom: 0 !important;
      justify-content: unset !important;
      list-style: none !important;

      a {
        color: $primary  !important;
        text-decoration: none !important;
        font-size: 1.1rem;
      }

      .nav-item {
        outline: none !important;
        cursor: pointer !important;
        border: none !important;
        font-weight: bolder !important;
        line-height: 24px !important;
        padding: 16px 22px !important;
        background-color: transparent !important;

        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;

        transition: color 0.16s ease-in-out, background-color 0.16s ease-in-out,
          border-color 0.16s ease-in-out !important;
      }

      .nav-item.active {
        background-color: $lightBlue  !important;
        color: $primary  !important;
        cursor: default !important;
      }
    }

    .table-striped {
      border: 1px solid #dbdbdb;

      // box-shadow: 1px 2px;
      thead {
        background: $lightBlue;
      }

      // tr:nth-child(odd)  {
      //   // background: $lightGrey;
      // }
      tr:nth-child(even) {
        background: $lightBlue;
      }
    }

    .data-table-container {
      td {
        white-space: normal;
      }
    }
  }
}

@media only screen and (max-width: 960px) and (max-device-width: 1024px) {
  #claimsContainer {
    .claimsHeader {
      display: inline-block !important;

      .leftSideContainer {
        word-break: break-word;

        .ui.breadcrumb {
          display: inline;
          margin: 0;

          #breadCrumbSection {

            &::before {
              display: none;
            }

            &::after {
              display: none;
            }
          }
        }
      }

      .buttonContainer {
        //display: none;
        display: block;
      }
    }

    #claimsTabs {
      width: unset !important;

      .tab-content {
        display: table !important;
        width: 100% !important;

        .tab-pane.active {
          display: table !important;

          .list-item {
            display: table !important;
            padding-top: 10px;
          }
        }
      }
    }

    _ .tabs {
      width: 91% !important;
      overflow-x: scroll;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        scrollbar-width: thin;
        border-radius: 4px;
        height: 0.5rem;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
    }

    .section {
      flex-direction: row !important;
    }

    // for the claims table only
    #claimsTabs {
      #claimsForPolicy {

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          margin: 0 0 1rem 0;
        }

        tr:nth-child(odd) {
          background: #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          // position: absolute;
          /* Top/left values mimic padding */
          top: 0;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          font-weight: bolder;
          white-space: nowrap;
        }

        .data-table-container table td:not(.note),
        .data-table-container table th:not(.note) {
          white-space: pre-wrap;
        }

        /*
          Label the data
          You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
          */
        td:nth-of-type(1):before {
          content: "Claim:";
        }

        td:nth-of-type(2):before {
          content: "Loss Date:";
        }

        td:nth-of-type(3):before {
          content: "Claimant:";
        }

        td:nth-of-type(4):before {
          content: "Loss Type:";
        }

        td:nth-of-type(5):before {
          content: "Status:";
        }

        td:nth-of-type(6):before {
          content: "Total Incurred:";
        }
      }
    }


    // for the single Claim Table only
    #claimsTabs {
      #singleClaimTable {

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
          position: relative;
          top: 0;
          left: 0;

          th {
            display: none;
          }

          th:first-of-type {
            display: block;
          }
        }

        tr {
          margin: 0 0 1rem 0;
        }

        tr:nth-child(odd) {
          background: #ccc;
        }

        td,
        th {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          // padding-left: 50%;
        }

        td:before,
        th:before {
          /* Now like a table header */
          // position: absolute;
          /* Top/left values mimic padding */
          top: 0;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          font-weight: bolder;
          white-space: nowrap;
        }

        .data-table-container table td:not(.note),
        .data-table-container table th:not(.note) {
          white-space: pre-wrap;
        }

        /*
          Label the data
          You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
          */
        td:nth-of-type(even):after {
          margin-bottom: 10px;
        }

        td:nth-of-type(1):before {
          content: none;
        }

        td:nth-of-type(2):before {
          content: none;
        }

        td:nth-of-type(3):before {
          content: none;
        }

        td:nth-of-type(4):before {
          content: none;
        }

        td:nth-of-type(5):before {
          content: none;
        }

        td:nth-of-type(6):before {
          content: none;
        }


        // combine header with first row only
        tbody {
          tr:first-of-type {
            td:nth-of-type(3):before {
              content: "Recovery";
            }

            td:nth-of-type(4):before {
              content: "Loss Reserve";
            }

            td:nth-of-type(5):before {
              content: "Loss Paid";
            }

            td:nth-of-type(6):before {
              content: "Expense Reserve";
            }

            td:nth-of-type(7):before {
              content: "Expense Paid";
            }

            td:nth-of-type(8):before {
              content: "Total incurred";
            }
          }

        }


        tbody:first-child:nth-child(4):before {
          content: "Loss Reserve";
        }

        tbody:first-child:nth-child(5):before {
          content: "Loss Paid";
        }

        tbody:first-child:nth-child(6):before {
          content: "Expense Reserve";
        }

        tbody:first-child:nth-child(7):before {
          content: "Expense Paid";
        }

        tbody:first-child:nth-child(8):before {
          content: "Total incurred";
        }

        /////
        //reset the labels coming from other style sheets

      }
    }

    // for the Notes table only
    #claimsTabs {
      #notesForClaim {

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          margin: 0 0 1rem 0;
        }

        tr:nth-child(odd) {
          background: #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          // position: absolute;
          /* Top/left values mimic padding */
          top: 0;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          font-weight: bolder;
          white-space: nowrap;
        }

        .data-table-container table td:not(.note),
        .data-table-container table th:not(.note) {
          white-space: pre-wrap;
        }

        /*
          Label the data
          You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
          */
        td:nth-of-type(1):before {
          content: "Effective Date:";
        }

        td:nth-of-type(2):before {
          content: "Subject:";
        }

        td:nth-of-type(3):before {
          content: "Note:";
        }
      }
    }

    //claim details
    #claimsTabs {
      .section {
        display: flex;
        flex-direction: column !important;

        .gridContainer {
          display: grid;
          grid-template-columns: 2fr 1fr;

          // .boldtext{
          //   font-weight: bold;
          // }
          .leftColumn {
            margin-right: 20%;
          }

          .rightColumn {
            .roundButton {
              width: 100% !important;
            }
          }

          .bottomMarginLarge {
            margin-bottom: 1.5rem;
          }

          .flex-spaceBetween {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}