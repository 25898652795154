@import "../../../variables";

#MotorVehicleRecordsPageContainer {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 40px;
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .headerButtonContainer{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
    }
  }
  .videoContainer {
    width: 100%;
    height: 600px;
    margin-bottom: 2rem;
    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 15px 60px 50px 50px;
    .heading2 {
      padding: 15px 0px;
      font-weight: bolder;
    }
    #form-text {
      height: 2.5rem;
      width: 100%;
      border: none;
      font-size: 2.5rem;
    }
  }
  .checkbox-holder {
    display: flex;
    flex-direction: row;
    padding: 30px 0px;
    #checkbox {
      margin-right: 20px;
      height: 30px;
      width: 30px;
    }
    div {
      line-height: 2.2;
    }
  }
  .buttonHolder {
    display: flex;
    flex-direction: row;
    button {
      width: unset !important;
      min-width: 210px !important;
    }
    :first-child {
      margin-right: 50px;
    }
  }
}

////// MOBILE STYLING
@media screen and (max-width: 767px) {
  #MotorVehicleRecordsPageContainer {
    .headerContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .videoContainer {
      width: 100%;
      height: 200px;
      margin-bottom: 2rem;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
  #MotorVehicleRecordsPageContainer .buttonHolder {
    display: table !important;
  }
  #MotorVehicleRecordsPageContainer .section #form-text {
    font-size: 1rem;
  }

  #MotorVehicleRecordsPageContainer .checkbox-holder #checkbox {
    margin-right: 20px !important;
    height: 30px !important;
    width: 30px !important;
    margin-top: 0px !important;
  }
}
