@import "../../variables";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  z-index: 9999999; // be on top plz
  background: $primary;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    max-width: 35%;
    height: auto;
    animation: spin 3s infinite linear;
  }
}
