@import "../../variables";

#TwoColumnDataContainer {
  font-size: 1em;
  margin-right: 3em;
    .colorDivider{
        margin-block-start: 1em;
        margin-block-end: 1em;
        border-color: $secondary;
    }
    .tableSection{
        display: flex;
        flex-direction: column;
        .table-item{
          display: grid;
         grid-template-columns: 2fr 1fr;
          text-align: right;
          align-items: center;
          margin-bottom: 5px;
          :first-child{
            text-align: left;
          }
          &.bold{
              font-weight:bold;
              padding: 15px 60px 0px 30px;
              line-height: 0;
          }
          .hidden{
            display: none;
          }
          .boldText{
            font-weight:bold;
          }
          .marginTopBottom{
            margin-top:1rem;
            margin-bottom:1rem;
          }
          .marginTop{
            margin-top:1rem;
          }
          .orangeTextButton{
            font-weight:bold;
            color: $secondary;
            border-left: 3px solid $secondary;
            padding-left: 10px;
            cursor: pointer;
          }
          .orangeText{
            font-weight:bold;
            color: $secondary;
            cursor: pointer;
          }
          .underline{
            border-bottom: 1px solid black;
            text-align: right;
            width: 100%;
          }
          .spacer{
            margin-top: 15px;
          }
        }
    }
}

@media screen and (max-width: 767px) {
    #TwoColumnDataContainer {
        .tableSection{
            .heading1{
                font-size: 1.8rem;
            }
            .section{
                .table-item, .table-item.bold{
                    padding: 15px 10px 0px 10px;
                    :first-child{
                        margin-right: 30px;
                    }
                    :last-child{
                        text-align: right;
                    }
                }
            }
        }
    }
}