@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap");
@import "variables";

// *,
// *:before,
// *:after {
//   box-sizing: border-box;
// }

body {
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary !important;
  width: 100vw;
  // overflow-x: hidden;
  overflow: hidden;

  &:hover {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// partial disable of the scroll bounce, left on html
body,
#root {
  //overflow: hidden;
  margin: none;
  padding: none;
}

////// counteracts styling imported from rbx
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: revert;
  padding: revert;

}

*,
*::before,
*::after {
  box-sizing: unset;
}

.list-item:not(:last-child) {
  border-bottom: none;
}

// all greyed out items based on permissions
.isDisabled {
  color: grey !important;
  font-style: italic;
}

// all rbx select form fields
.selectField {
  padding-right: 0 !important;
}

.requiredStar {
  color: $secondary;
}

////// MOBILE STYLING
@media screen and (max-width: 767px) {
  body {
    font-size: 12px;
  }
}

@import "~bulma/bulma";
@import "~rbx/rbx";