@import "../../variables";

.statusBadge {
  .badge {
    color: $white !important;
    font-weight: bolder !important;
    width: 75px !important;
    padding: 4px 20px !important;
    border-radius: 25px !important;
    text-align: center !important;
  }
  // colors by type
  .Expired {
    background-color: $red !important;
  }
  .Active,
  .Complete {
    background-color: $green !important;
  }
  .ToDo {
    background-color: $secondary !important;
  }
}
