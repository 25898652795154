#groupSelectPage {
  .section {
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    margin-top: 20rem;
    .field,
    div {
      margin: auto;
    }
    div {
      padding-bottom: 1rem;
    }
  }
}
