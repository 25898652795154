@import "../../variables";

#calendarSquare{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:130px !important;
  width:140px !important;
  background: white;
  text-align: center;
  .bigText{
    font-size: 3rem;
    margin: 0.5rem;
    font-weight: bold;
  }
  .titleText{
    font-size: 1rem;
    margin: 0.5rem;
    padding-top: 0.5rem;
    font-weight: bold;
  }
  #orangeLine{
    border-top: 2px solid $secondary !important;
    margin: 0;
  }
}