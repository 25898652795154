@import "../../variables";
html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  .mainContentContainer {
    height: 100%;
    width: 100%;
  }
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 250ms ease;
}

.LandingPageContainer {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/BackgroundImage.jpg");
  color: white;

  .landingPageContent {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      margin-left: 1.5rem;
      margin-top: 1.5rem;
      width: 500px;
      margin-bottom: 3rem;
    }
    h1 {
      font-size: 2rem;
      font-weight: 800;
      display: flex;
      margin-block-end: 0;
      margin-block-start: 0;
      margin-inline-end: 0;
      margin-inline-start: 0;
    }
    .text-container {
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .button-container {
      width: 440px;
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        flex: 0.5;
      }
    }
  }

  // animated plus sign
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes plus-x {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }

  /* Standard syntax */
  @keyframes plus-x {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }

  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes plus-y {
    from {
      height: 0px;
    }
    // to { height: 47px;}
    to {
      height: 30px;
    }
  }

  /* Standard syntax */
  @keyframes plus-y {
    from {
      height: 0px;
    }
    // to { height: 47px;}
    to {
      height: 30px;
    }
  }

  .animated-plus {
    margin: 5px 20px 0px 8px;
    &::before {
      content: "";
      // width: 47px;
      width: 30px;
      // height: 8px;
      height: 3px;
      display: inline-block;
      // margin-bottom: 19px;
      margin-bottom: 13px;
      background-size: 200% 100%;
      background-image: linear-gradient(
        to left,
        transparent,
        transparent 50%,
        #f57f20 50%,
        #f57f20
      );
      -webkit-animation: plus-x 1s linear;
      animation: plus-x 1s linear;
      // animation-delay: 2s;
      animation-delay: 1s;
      -webkit-animation-fill-mode: forwards;
      background-position: 100% 0;
    }
    &::after {
      content: "";
      // width: 8px;
      width: 3px;
      display: inline-block;
      // margin-left: -47px;
      margin-left: -17px;
      background-color: $secondary;
      -webkit-animation: plus-y 1s;
      animation: plus-y 1s;
      // animation-delay: 3s;
      animation-delay: 2s;
      -webkit-animation-fill-mode: forwards;
      -webkit-transform: rotate(180deg); /* Safari */
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 767px) {
  .landingPageContent{
    .text-container {
      width: 90%;
      display: inline-block !important;
    }
    h1{
      text-align:left;
      flex-direction: column;
    }
  }
}