@import "../../variables";





#LossrunBatchModalContainer {
    #iconTooltip {
        font-weight: bold;
    }

    .colorDivider {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    td {
        word-wrap: break-word
    }

    #SelectiontableContainer {
        :first-child {
            margin-bottom: 0em;
        }

        .colorDivider {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        .tableContainer {
            display: grid;
            grid-template-columns: 2fr 2fr;
            grid-gap: 20px;
            padding-bottom: 25px;
            font-size: 1.1rem;

            .table-item {
                display: flex;
                flex-direction: column;
                text-align: left;
                padding: 0px 20px 2px 3px;

                :first-child {
                    font-weight: bold;
                    width: 100%;
                }
            }
        }

    }

    .buttonContainer {
        .roundButton {
            width: auto !important;
        }
    }

}


@media only screen and (max-width: 767px) and (max-device-width: 1024px) {

    #LossrunBatchModalContainer {
        #SelectiontableContainer {
            :first-child {
                margin-bottom: 0em;
            }

            .colorDivider {
                margin-block-start: 1em;
                margin-block-end: 1em;
            }


            .tableContainer {
                display: grid;
                grid-template-columns: 2fr 2fr;
                grid-gap: 20px;
                padding-bottom: 25px;
                font-size: 1.1rem;

                .table-item {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    padding: 0px 20px 2px 3px;

                    :first-child {
                        font-weight: bold;
                        width: 100%;
                    }
                }
            }
        }

        .buttonContainer {
            .roundButton {
                width: auto !important;
            }
        }
    }
}