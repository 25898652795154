@import "../../../variables";

#MessageBoardContainer {
  .FeaturedSection {
    display: flex;
    flex-direction: column;
    width: unset;
    .section {
      :first-child {
        flex-grow: 0;
      }
      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;
      .heading2 {
        margin-bottom: 20px;
        padding: 5px 30px;
      }
      .imageItem {
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 15px 60px 20px 30px;
        .image-item-text {
          .topText {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
  .WebinarSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: unset;
    .section {
      :first-child {
        flex-grow: 0;
      }
      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;
      .heading2 {
        margin-bottom: 20px;
        padding: 5px 30px;
      }
      .imageItem {
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 15px 60px 20px 30px;
        .image {
          height: 120px;
        }
        .image-item-text {
          .topText {
            padding-bottom: 30px;
            font-size: 1.3rem;
            line-height: 1.5;
          }
        }
      }
    }
    .roundButton {
      margin-left: 30%;
    }
  }
  .NewsLetter{
    display: flex;
    flex-direction: row;
    align-items: center;
    .section{
      width: 100%;
    }
    .heading2{
      margin-right: 1rem;
    }
    svg{
      color: $secondary;
      cursor: pointer;
     
      &:hover{
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #MessageBoardContainer {
    .FeaturedSection {
      width: 100%;
      .heading1 {
        font-size: 1.8rem;
      }
      .section {
        .imageItem {
          width: 100%;
          display: table;
          .image-item-text {
            font-size: medium !important;
            h3 {
              font-size: medium !important;
            }
          }
        }
      }
    }
    .WebinarSection {
      width: 100%;
      .section {
        .imageItem {
          width: 100%;
          display: table;
          .image-item-text {
            font-size: medium !important;
            h3 {
              font-size: medium !important;
            }
          }
        }
      }
      .roundButton {
        margin-left: 0;
      }
    }
  }
}
