@import "../../../variables";

#LPSContainer {
  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  .LPSHeader {
    display: flex;
    justify-content: space-between;

    .section {

      &:first-child {
        //margin-right: 50px !important ;
        padding: 25px 10px;
      }

      :first-child {
        flex-grow: 0;
      }

      flex-direction: column;
      padding: 25px 40px;


      .heading3 {
        text-align: center;
        margin-bottom: 35px;
        font-size: 1.2em !important;
      }

      #iconTooltip {
        font-weight: bold;
      }

      .LCT-List-item {
        display: flex;
        font-size: 1.1rem;

        :first-child {
          width: 100%;
        }

        &.bold {
          font-weight: bold;
          padding-top: 0;
        }
      }

      .textButton {
        position: relative;
        margin: 60px 0 0 0;
      }
    }
  }

  .tableSection {
    display: flex;
    flex-direction: column;

    .section {
      &:first-child {
        margin-right: 50px !important;
      }

      :first-child {
        flex-grow: 0;
      }

      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;

      .heading2 {
        margin-bottom: 35px;
        padding: 5px 30px;
      }

      .table-item {
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 0px 60px 15px 30px;

        :first-child {
          width: 70%;
        }

        &.bold {
          font-weight: bold;
          padding: 15px 60px 0px 30px;
          line-height: 0;
        }
      }
    }
  }

  .SCSection {
    display: flex;
    flex-direction: column;

    .section {
      &:first-child {
        margin-right: 50px !important;
      }

      :first-child {
        flex-grow: 0;
      }

      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 0px;

      .heading2 {
        margin-bottom: 20px;
        padding: 5px 30px;
      }

      .imageItem {
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 0px 60px 15px 30px;
      }
    }
  }

  //Li add for best practice 
  #LPStableContainer {
    .colorDivider {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    .tableSection {
      display: flex;
      flex-direction: column;

      .section {
        :first-child {
          flex-grow: 0;
        }

        display: flex;
        flex-direction: column;
        padding: 20px 0px 5px 0px;

        .heading2 {
          margin-bottom: 20px;
          padding: 5px 30px;
        }

        .tableContainer {
          display: grid;
          grid-template-columns: 2fr 2fr;
          grid-gap: 5px;
          font-size: 1.1rem;

          .table-item {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 0px 20px 2px 3px;

            :first-child {
              font-weight: bold;
              width: 100%;

              .inputCheckBox {
                width: 15px !important;
                height: 15px !important;
              }

              /*here*/
              /*Li Hack the checkbox to change the color and checkmark*/
              [type="checkbox"]:not(:checked),
              [type="checkbox"]:checked {
                position: relative;
                left: 0;
                opacity: 0.01;
              }

              [type="checkbox"]:not(:checked)+span,
              [type="checkbox"]:checked+span {
                position: relative;
                padding-left: 1.7em;
                line-height: 1.7;
                height: 15px;
                width: 15px;

              }

              /* Style its checked state...with a ticked icon */
              input[type=checkbox]:not(checked)+span:before {
                content: '';
                position: absolute;
                top: -2px;
                left: 0;
                bottom: -2px;
                text-align: center;
                border: 1px solid darkgrey;
                height: 22px;
                width: 22px;
                border-radius: 3px;
                background-color: #124a80;
              }

              input[type=checkbox]:checked+span:before {
                content: '✔';
                position: absolute;
                top: -2px;
                left: 0;
                bottom: -2px;
                text-align: center;
                color: #f47e20;
                border: 1px solid darkgrey;
                height: 22px;
                width: 22px;
                border-radius: 3px;
              }

              /*Li Hack the checkbox to change the color and checkmark*/
            }
          }
        }
      }
    }
  }

  //Li add warranties and recommdations
  .SubSection {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1280px) {
  #LPSContainer {
    .LPSHeader {
      display: inline-block;
      width: 100%;

      .section {
        width: 100%;
      }
    }

    .tableSection {
      .heading1 {
        font-size: 1.8rem;
      }

      .section {

        .table-item,
        .table-item.bold {
          padding: 15px 10px 0px 10px;

          :first-child {
            margin-right: 30px;
          }

          :last-child {
            text-align: right;
          }
        }
      }
    }

    .SCSection {
      .section {
        .heading2 {
          padding: 0;
        }

        .imageItem {
          display: table;
          padding: 0px 60px 15px 0px;

          .image-item-text {
            padding-left: 0;
          }
        }
      }
    }

    #LPStableContainer {
      .tableSection {
        display: flex;
        flex-direction: column;

        .section {
          :first-child {
            flex-grow: 0;
            margin-right: 15px;
          }

          display: inline-block;
          padding: 20px 0px 5px 0px;

          .heading2 {
            margin-bottom: 20px;
            padding: 5px 30px;
          }

          .tableContainer {
            display: table-row;
            font-size: 1.1rem;

            .table-item {
              display: table-row;
              text-align: left;
              padding: 0px 20px 2px 3px;

              :first-child {
                font-weight: bold;

                .inputCheckBox {
                  width: 15px !important;
                  height: 15px !important;
                }

                /*here mobile*/
                /*Li Hack the checkbox to change the color and checkmark*/
                [type="checkbox"]:not(:checked),
                [type="checkbox"]:checked {
                  position: absolute;
                  left: 0;
                  opacity: 0.01;
                }

                [type="checkbox"]:not(:checked)+span,
                [type="checkbox"]:checked+span {
                  position: relative;
                  padding-left: 1.7em;
                  line-height: 1.7;
                  height: 15px;
                  width: 15px;

                }

                /* Style its checked state...with a ticked icon */
                input[type=checkbox]:not(checked)+span:before {
                  content: '';
                  position: absolute;
                  top: -2px;
                  left: 0;
                  bottom: -2px;
                  text-align: center;
                  border: 1px solid darkgrey;
                  height: 22px;
                  width: 22px;
                  border-radius: 3px;
                }

                input[type=checkbox]:checked+span:before {
                  content: '✔'; //'✔'; √
                  position: absolute;
                  top: -2px;
                  left: 0;
                  bottom: -2px;
                  text-align: center;
                  color: #f47e20;
                  border: 1px solid darkgrey;
                  height: 22px;
                  width: 22px;
                  border-radius: 3px;
                }

                /*Li Hack the checkbox to change the color and checkmark*/
              }
            }
          }
        }
      }
    }

  }
}