@import "../../../variables";

.textButton {
  border-left: 2px solid $secondary;
  padding-left: 4px;
  color: $secondary;
  font-weight: 700;
  font-size: 1.1em;
  height: fit-content;
  line-height: 1rem;
  margin: auto;
  cursor: pointer;
  &:hover {
    color: $primary !important;
    border-color: $primary;
  }
  &.isDisabled {
    border: none;
    color: grey !important;
  }
}
.lockIcon {
  font-size: 1.5rem;
  margin: auto 0 auto 0;
}
