@import "../../variables";

.footer {
  position: fixed;
  background-color: $primary !important;
  height: 4rem;
  width: 100%;
  bottom: 0;
  z-index: 900;
  color: $white;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 !important;
}
.footerItem {
  appearance: none;
  background: none;
  border: none;
  margin-right: 20px;
  font-size: 1.1em;
  color: $white;
  text-decoration: none;
  cursor: pointer;
  transition: color 250ms ease;
  outline: none;
  &:hover {
    color: $secondary;
  }
  &.footer-item-logout {
    color: $secondary;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    justify-content: none;
    a.footerItem:first-of-type {
      margin-left: 16%;
    }
    .footerItem {
      font-size: 0.8em;
      text-align: center;
      margin-left: 10px;
    }
  }
}
