#EmailClaimContainer {
  .field {
    max-width: calc(100% - 16px);
    padding-right: 10px;
    padding-left: 20px;
  }

  .field-label {
    flex-basis: 120px;
    flex-grow: 0;
  }


  .inputCheckBoxLabel {
    font-weight: 700;
    color: #363636;
    margin-bottom: 10%;
  }

  .inputCheckBox {
    height: 15px !important;
    width: 15px !important;
  }

}

#EmailClaimUpload {
  .heading3 {
    font-weight: 700;
    padding-bottom: 10px;
  }

  .section {
    display: flex;
    flex-direction: column !important;
  }

  button {
    //replicate file upload button
    padding: 6px 1px 8px 1px !important;
    width: 165px !important;
    background-color: whitesmoke !important;
    border-radius: 4px !important;
    border: solid 1px;
    border-color: #dbdbdb !important;
    font-size: 1em;
    color: #363636;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}


@media only screen and (max-width: 1024px) {
  #EmailClaimContainer {

    .field {
      max-width: calc(100% - 10px);
      //max-width: fit-content !important;
      padding-right: 1px;
      padding-left: 2px;
    }

    .field-label {
      flex-basis: 150px !important;
      flex-grow: 0 !important;
      text-align: left !important;


    }

    .is-horizontal {
      display: grid !important;
      //flex-direction: column !important;

      grid-template-columns: 1fr;
    }


  }
}