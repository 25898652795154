@import "../../variables";

.sideButton{
  color: $secondary;
  background: transparent;
  border: 0;
}

.carousel .slide{
  background: transparent;
}

.carousel {
  &.carousel-slider{
  width: 100%;
  padding: 0 5%;
  }
}

// .carousel .slider-wrapper.axis-horizontal .slider {
// display: inherit !important;
// }