@import "../../../variables";

#userContainer,
#user-edit-page {
  .headerContainer {
    display: flex;

    :first-child {
      flex: 1;
    }
  }

  .field {
    max-width: calc(100% - 16px);
  }
}

#UserFormContainer {

  // added back in here so that styling which was counteracted (between rbx/index.css and top level css; see top level index.js) is now here for rbx fields
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    padding: 0 !important;
  }

  // #userContainer {
  //   svg {
  //     color: $secondary !important;
  //   }
  //   @media only screen and (max-width: 767px) and (max-device-width: 1024px) {
  //     .headerContainer {
  //       display: block;
  //     }
  //     /* Force table to not be like tables anymore */
  //     table,
  //     thead,
  //     tbody,
  //     th,
  //     td,
  //     tr {
  //       display: block;
  //     }

  //     /* Hide table headers (but not display: none;, for accessibility) */
  //     thead tr {
  //       position: absolute;
  //       top: -9999px;
  //       left: -9999px;
  //     }

  //     tr {
  //       margin: 0 0 1rem 0;
  //     }

  //     tr:nth-child(odd) {
  //       background: #ccc;
  //     }

  //     td {
  //       /* Behave  like a "row" */
  //       border: none;
  //       border-bottom: 1px solid #eee;
  //       position: relative;
  //       // padding-left: 50%;
  //     }

  //     td:before {
  //       /* Now like a table header */
  //       // position: absolute;
  //       /* Top/left values mimic padding */
  //       top: 0;
  //       left: 6px;
  //       width: 45%;
  //       padding-right: 10px;
  //       font-weight: bolder;
  //       white-space: nowrap;
  //     }

  //     .data-table-container table td:not(.note),
  //     .data-table-container table th:not(.note) {
  //       white-space: pre-wrap;
  //     }

  //     /*
  //   Label the data
  //   You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  //   */
  //     td:nth-of-type(1):before {
  //       content: "RecId:";
  //     }
  //     td:nth-of-type(2):before {
  //       content: "FirstName:";
  //     }
  //     td:nth-of-type(3):before {
  //       content: "LastName:";
  //     }
  //     td:nth-of-type(4):before {
  //       content: "Email:";
  //     }
  //     td:nth-of-type(5):before {
  //       content: "Phone:";
  //     }
  //     td:nth-of-type(6):before {
  //       content: "IsActive:";
  //     }
  //   }
  // }

  // .buttonContainer {
  //   padding-top: 2rem;
  // }
}

@media only screen and (max-width: 767px) and (max-device-width: 1024px) {

  #userContainer {

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      width: 100%;
      display: table;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;

    }

    // tr:nth-child(odd) {
    //   background: #ccc;
    // }

    tr:nth-child(even) {
      background: $paleBlue  !important;
    }

    tr:nth-child(odd) {
      background: $lightGrey  !important;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      min-width: 100%;
      // padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      // position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      font-weight: bolder;
      white-space: nowrap;
    }

    .data-table-container table td:not(.note),
    .data-table-container table th:not(.note) {
      white-space: pre-wrap;
    }

    /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
    td:nth-of-type(1) {
      display: none;
    }

    td:nth-of-type(2):before {
      content: "FirstName:";
    }

    td:nth-of-type(3):before {
      content: "LastName:";
    }

    td:nth-of-type(4):before {
      content: "Email:";
    }

    td:nth-of-type(5):before {
      content: "Phone:";
    }

    td:nth-of-type(6):before {
      content: "Buttons:";
    }
  }
}