@import "../../variables";

.modal {
  z-index: 9999 !important;
  .modalContainer {
    background-color: $lightBlue;
    padding: 1em;
    color: $primary;
    text-align: center;
    :first-child {
      margin-bottom: 2em;
    }
    .buttonContainer {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
