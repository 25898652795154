@import "../../variables";

.header {
  position: fixed;
  background: $primary;
  height: 17vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    max-width: 40%;
  }

  img {
    width: 33%;
    min-width: 340px;
    margin-left: 3%;
  }

  .active-route {
    padding: 2rem;
    float: right;
    font-size: 2.4rem;
    font-weight: bold;
  }

  #settings-menu {
    position: fixed;
    top: 0;
    right: 0;

    #settings-select-container {
      .settings-select {
        border: none !important;
        background: $primary;
        color: $white;
        box-shadow: none !important;
      }
    }
  }

  #settings-menu-placeHolder {
    position: fixed;
    top: 5px;
    right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 12vh;

    a {
      margin-left: 50px;
    }

    .active-route {
      display: none;
    }

    img {
      width: 33%;
      min-width: 200px;
      margin-left: 6%;
    }

    #settings-menu {
      position: fixed;
      top: 2vh;
      right: 0;

      #settings-select-container {
        font-size: 1rem;
        text-align: right;
        text-align-last: right;

        .settings-select {
          text-align-last: right;
          border: none !important;
          background: transparent;
          color: $white;
          box-shadow: none !important;
        }
      }
    }
  }
}