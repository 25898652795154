.indexedStep {
    color : white;
    width: 25px;
    height: 25px;
    font-size: 12px;
    background-color:rgba(211, 211, 211, 0.6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: #f47e20;
  }
  .RSPBprogressBar{
      height: 15px !important;
  }