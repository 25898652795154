
.claimsResourcesPage{
  display: flex;
  flex-direction: column;
  .section{
    display: flex;
    padding: 20px 25px;
    :first-child{
      flex-grow: 1
    }
  }
}
