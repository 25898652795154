@import "../../variables";

@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

*:focus {
  outline: none;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sidebar {
  height: 83vh;
  bottom: 0;
}
.sidebar,
.mobileSidebar {
  position: fixed;
  z-index: 9999;
  background: $black;
  padding-top: 10px;
  left: 0;
  color: $white;
  box-shadow: 1px 0px 4px 0px hsla(0, 0%, 0%, 0.15);
  overflow: hidden;
  //menu
  .sidebar-container {
    position: relative;
    height: 100%;
    width: 0px;
    opacity: 0;
    transition: width 500ms, opacity 500ms;
    //section
    .sidebar-section {
      width: 22vw;
      padding-top: 0.8rem;
      padding-bottom: 1.2rem;
      display: flex;
      line-height: 1.3;
      color: $white;
      flex-direction: column;
      //titles
      span {
        font-weight: 800;
        font-size: 1.2em;
        padding-left: 1rem;
        padding-bottom: 0.2rem;
      }
      .divider {
        border-bottom: 3px solid $secondary;
        width: 60%;
        margin-bottom: 0.5rem;
      }
      //page links
      .sidebar-link {
        cursor: pointer;
        padding-left: 1rem;
        font-size: 1.1em;
        line-height: 1.5;
        text-decoration: none;
        color: $white;
        transition: color 200ms;
        &:hover {
          color: $secondary;
        }
        &.is-active {
          color: $secondary;
        }
        &.isDisabled {
          pointer-events: none;
        }
      }
      .dashboardLink {
        font-weight: 800;
        font-size: 1.2em;
        padding-left: 1rem;
        padding-top: 1rem;
        cursor: pointer;
        text-decoration: none;
        transition: color 200ms;
        color: $white;
        line-height: 1.5;
        &:hover {
          color: $secondary;
        }
        &.is-active {
          color: $secondary;
        }
      }
      .dashboardDivider {
        border-bottom: 3px solid $secondary;
        width: 60%;
      }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  // change width and opacity when toggled
  .open {
    width: 22vw;
    opacity: 1;
    * {
      // display:none;
      -webkit-animation: fadein 0.5s forwards; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 0.5s forwards; /* Firefox < 16 */
      animation: fadein 0.5s forwards;
    }
  }
  .closed {
    width: 50px;
    opacity: 1;
    * {
      // display:none;
      -webkit-animation: fadeout 0.3s forwards; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadeout 0.3s forwards; /* Firefox < 16 */
      animation: fadeout 0.3s forwards;
    }
  }

  ////// for the arrow
  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: $secondary;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $black;
    right: 1.125em;
    z-index: 4;
  }

  .select select:not([multiple]) {
    padding-right: 2.75em;
    &:hover {
      color: #3273dc;
    }
  }
  ///////
  //toggle button
  .sidebar-toggle {
    cursor: pointer;
    outline: none;
    background: transparent;
    height: 120px;
    width: 20px;
    position: absolute;
    right: 35px;
    top: 8px;
    bottom: calc(85% - 25px);
    border-radius: 0px 15px 15px 0px;
    .sidebarIconContainer {
      .sidebarIcon {
        font-size: 1.2em;
        padding: 1px;
        cursor: pointer;
        &:hover {
          color: $secondary;
        }
      }
    }
  }
}
.sidebar {
  overflow: hidden;
  .open {
    &:hover {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.mobileSidebar {
  overflow: hidden !important;
}

/// HAMBURGER
.hamburger {
  &:hover {
    color: $secondary;
    opacity: 1;
  }
  .hamburger-box {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $white;
      width: 30px;
      &:hover {
        color: $secondary;
        background-color: $secondary !important;
      }
    }
  }
}

// ////// MOBILE STYLING
// @media screen and (max-width: 767px) {
//   .sidebar {
//     height: calc(100% - 12vh);
//     .open {
//       width: 75vw;
//     }
//     .sidebar-container {
//       .sidebar-section {
//         width: 70vw;
//       }
//     }
//   }
// }

.mobileSidebar {
  top: 0;
  position: absolute;

  .sidebar-container {
    .sidebar-section {
      width: 70vw;
    }
  }
  .closed {
    height: 10vh;
  }
  .open {
    height: 100%;
    width: 75vw;
    padding-bottom: 1rem;
  }
  .hamburger {
    margin-top: 0.5rem;
  }
}
