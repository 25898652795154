@import "../../variables";

@media only screen and (max-width: 767px) and (max-device-width: 1024px) {

  #activityLog{
    .summaryHeader{
      flex-direction: column;
    }
    .summaryReport{
      display: flex;
      flex-direction: column;
      .section{
        display: flex;
        flex-direction: column;
        width: unset;
        .three-column-grid{
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      width: 100%;
      display: table;
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr {
      margin: 0 0 1rem 0;

    }
  
    // tr:nth-child(odd) {
    //   background: #ccc;
    // }
  
    tr:nth-child(even) {
      background: $paleBlue !important;
    }
    tr:nth-child(odd)  {
      background: $lightGrey !important;
    }
  
    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      min-width: 100%;
      // padding-left: 50%;
    }
  
    td:before {
      /* Now like a table header */
      // position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      font-weight: bolder;
      white-space: nowrap;
    }
  
    .data-table-container table td:not(.note),
    .data-table-container table th:not(.note) {
      white-space: pre-wrap;
    }
  
    /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  td:nth-of-type(1):before {
    content: "User:";
  }
  td:nth-of-type(2):before {
    content: "TimeStamp:";
  }
  td:nth-of-type(3):before {
    content: "Action:";
  }
}
}