@import "../../variables";

#NotificationUserContainer{
  td{
    word-wrap: break-word
  }
}

#ClaimNotificationContainer {
  #iconTooltip {
    font-weight: bold;
  }
  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  
  #NotificationHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .leftSideContainer{
    display: flex;
    flex-direction: column;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    .roundButton{
      width: unset !important;
      flex: none !important;
    }
  }
  td{
    word-wrap: break-word
  }
  
  #SelectiontableContainer {
    .colorDivider {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    .tableSection {
      display: flex;
      flex-direction: column;
      #iconTooltip {
        font-weight: bold;
      }
      .section {
        :first-child {
          flex-grow: 0;
        }
        display: flex;
        flex-direction: column;
        padding: 20px 0px 5px 0px;
        .heading2 {
          margin-bottom: 20px;
          padding: 5px 30px;
        }
        .tableContainer {
          display: grid;
          grid-template-columns: 2fr 2fr;
          grid-gap: 5px;
          padding-bottom: 20px;
          font-size: 1.1rem;
          .table-item {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 0px 20px 2px 3px;
            :first-child {
              font-weight: bold;
              width: 100%;
              .inputCheckBox {
                width: 15px !important;
                height: 15px !important;
              }
              /*Li Hack the checkbox to change the color and checkmark*/
              [type="checkbox"]:not(:checked),
              [type="checkbox"]:checked {
                position: relative;
                left: 0;
                opacity: 0.01;
              }
              [type="checkbox"]:not(:checked) + span,
              [type="checkbox"]:checked + span{
                position: relative;
                padding-left: 1.7em;
                line-height: 1.7;
                height: 15px;
                width: 15px;
                
              }
              /* Style its checked state...with a ticked icon */
              input[type=checkbox]:not(checked) + span:before{
                content: '';
                position: absolute;
                top: -2px;
                left: 0;
                bottom: -2px;
                text-align:center;
                border: 1px solid darkgrey;
                height: 22px;
                width: 22px;
                border-radius: 3px;
                background-color: #124a80;
              }
              input[type=checkbox]:checked + span:before {
                content: '✔';
                position: absolute;
                top: -2px;
                left: 0;
                bottom: -2px;
                text-align:center;
                color:#f47e20;
                border: 1px solid darkgrey;
                height: 22px;
                width: 22px;
                border-radius: 3px;
              }
              /*Li Hack the checkbox to change the color and checkmark*/
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  #ClaimNotificationContainer {
    #NotificationHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .leftSideContainer{
      display: flex;
      flex-direction: row;
    }
    .buttonContainer {
      display: block;
      .roundButton{
        width: unset !important;
        flex: none !important;
      }
    }
    #SelectiontableContainer {
      .tableSection {
        display: flex;
        flex-direction: column;
        #iconTooltip {
          font-weight: bold;
        }
        .section {
          :first-child {
            flex-grow: 0;
            margin-right: 15px;
          }
          display: inline-block;
          padding: 20px 0px 5px 0px;
          .heading2 {
            margin-bottom: 20px;
            padding: 5px 30px;
          }
          .tableContainer {
            display: table-row;
            font-size: 1.1rem;
            .table-item {
              display: table-row;
              text-align: left;
              padding: 0px 20px 2px 3px;
              :first-child {
                font-weight: bold;
                .inputCheckBox {
                  width: 15px !important;
                  height: 15px !important;
                }
                /*Li Hack the checkbox to change the color and checkmark*/
                [type="checkbox"]:not(:checked),
                [type="checkbox"]:checked {
                  position: absolute;
                  left: 0;
                  opacity: 0.01;
                }
                [type="checkbox"]:not(:checked) + span,
                [type="checkbox"]:checked + span{
                  position: relative;
                  padding-left: 1.7em;
                  line-height: 1.7;
                  height: 15px;
                  width: 15px;
                  
                }
                /* Style its checked state...with a ticked icon */
                input[type=checkbox]:not(checked) + span:before{
                  content: '';
                  position: absolute;
                  top: -2px;
                  left: 0;
                  bottom: -2px;
                  text-align:center;
                  border: 1px solid darkgrey;
                  height: 22px;
                  width: 22px;
                  border-radius: 3px;
                }
                input[type=checkbox]:checked + span:before {
                  content: '✔';//'✔'; √
                  position: absolute;
                  top: -2px;
                  left: 0;
                  bottom: -2px;
                  text-align:center;
                  color:#f47e20;
                  border: 1px solid darkgrey;
                  height: 22px;
                  width: 22px;
                  border-radius: 3px;
                }
                /*Li Hack the checkbox to change the color and checkmark*/
              }
            }
          }
        }
      }
    

  }
}
}
