.reset {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 500px;
  max-width: 95%;
  margin: 0 auto;
  .title-holder,
  #reset-password-form {
    width: 100%;
    max-width: 100%;
  }
  .title-holder {
    margin-bottom: 1rem;
  }
  .button-container {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 1.5rem auto;
  }
}
