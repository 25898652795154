@import "../../../variables";
.confirmationPage{
    .section
    {
        display: inline-block;
        //flex-direction: column !important;
        text-align: center;
        font-weight: 600;
        p {
            padding: 2px;
            //padding-bottom: 2px;
        }
    }
    button 
    {//replicate file upload button
        padding: 6px 1px 8px 1px !important;
        width:165px !important;
        background-color: whitesmoke !important;
        border-radius: 4px !important;
        border:solid 1px;
        border-color: #dbdbdb !important;
        font-size: 1em;
        color: #363636;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        span {
            align-items: center;
            margin-right: 0.5em;
        }
    }
}

