@import "../../variables";

.section {
  background-color: $lightBlue;
  margin-bottom: 20px;
  padding: 1.4rem 1.6rem !important;
}

@media screen and (max-width: 767px) {
  .section {
    display: inline-block !important;
    width: 100%;
  }
}
