@import "../../variables";
.page-container {
  overflow: hidden;
  .content-section {
    margin: calc(17vh + 2.5rem) calc(10vw + 6rem) 2rem calc(10vw + 6rem);
    transition: margin-left 500ms, margin-right 500ms;
    padding-bottom: 6rem;
    overflow: hidden;
    &:hover {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    #watermark {
      height: 580px;
      position: fixed;
      right: -70px;
      bottom: -40px;
      z-index: -1;
      opacity: 50%;
    }
  }
  .shrink {
    margin-left: calc(21vw + 7rem);
    margin-right: 6rem;
  }
}

@media screen and (max-width: 767px) {
  .page-container {
    .content-section {
      margin: calc(100px + 2rem) 1rem 0 1rem;
    }
    .shrink {
      margin-left: calc(90px + 4rem);
      margin-right: 1rem;
    }
  }
}
