@import "../../../variables";

.summaryContainer {
  display: flex;
  justify-content: space-between;
  div {
    width: 350px;
  }
}
#standingCommitteeContainer, #meetingsContainer {
  .fileGroup {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .verticalFlex{
      display: flex;
      flex-direction: column;
    }
    .horizontalFlex{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .section {
      width: 160px !important;
      margin-right: 20px;
      margin-top: 20px;
      &#orgDoc{
        min-width: 200px;
      }
      .statementCard {
        text-align: center;
        svg {
            margin-bottom: 10px;
        }
        a {
          font-weight: 700;
          font-size: 0.8rem;
          color: $secondary;
          text-decoration: none;
        }
        :last-child {
            margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) and (max-device-width: 1024px) {
  #standingCommitteeContainer {
    .fileGroup{
      .verticalFlex{
        display: flex;
        flex-direction: column;
      }
      .horizontalFlex{
        display: flex;
        flex-direction: column;
      }
    }
  }
}
