@import "../../../variables";

.heading2 {
  margin: 0;
  font-size: 1.3em;
  font-weight: 1000;
  .form-star{
    color: #DD3407;
  }
}
