.imageItem {
  display: flex;
  flex-direction: row;
  .image {
    height: 110px;
    width: 160px;
  }
  .image-item-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.3rem;
    padding-left: 20px;
  }
}
