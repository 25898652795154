@import "../../../variables";

.PPCPAGE{
  .statementContainer {
    .fileGroup {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .outerStatementHolder{
        display: flex;
        flex-direction: column;
        width: 100%;
        .innerStatementHolder{
          display: flex;
          flex-direction: row;
          .section {
            width: 20%;
            margin-right: 1.2rem;
            margin-top: 1.2rem;
            .statementCard {
              text-align: center;
              padding-top: 0.8rem;
              padding-bottom: 0.8rem;
              svg {
                margin-bottom: 10px;
              }
              a {
                font-weight: 700;
                font-size: 0.9rem;
                color: $secondary;
                text-decoration: none;
              }
              :last-child {
                margin-top: 10px;
              }
            }
          }
        }
      }
      
    }
  }
  .statementContainerMFA {
    .fileGroup {
      display: flex;
      justify-content: flex-start;
      .section {
        width: 20%;
        margin-right: 1.2rem;
        margin-top: 1.2rem;
        .statementCard {
          text-align: center;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          svg {
            margin-bottom: 10px;
          }
          a {
            font-weight: 700;
            font-size: 0.9rem;
            color: $secondary;
            text-decoration: none;
          }
          :last-child {
            margin-top: 10px;
          }
        }
      } 
    }
  }
}


@media screen and (max-width: 767px) {
  .PPCPAGE {
    flex-direction: column;
    .statementContainer {
      .fileGroup {
        display: block;
        .section {
          width: 80%;
        }
      }
    }
  }
}