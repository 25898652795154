@import "../../../variables";

#MemDirectoryContainer {
  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  .tableSection {
    display: flex;
    flex-direction: column;
    .section {
      :first-child {
        flex-grow: 0;
      }
      display: flex;
      flex-direction: column;
      padding: 20px 0px 5px 0px;
      .heading2 {
        margin-bottom: 20px;
        padding: 5px 30px;
      }
      .tableContainer {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-gap: 10px;
        font-size: 1.1rem;
        .table-item {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 0px 30px 15px 30px;
          :first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #MemDirectoryContainer {
    .tableSection {
      .tableContainer {
        display: flex;
        flex-direction: column;
        .table-item {
          grid-column-end: span 12;
        }
      }
    }
    .section {
      width: 100%;
    }
  }
}
