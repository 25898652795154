@import "../../../../variables";

#StepLabel {
  display: flex !important;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: -35px;

  .heading3 {
    width: 25%;
    text-align: center;
    font-size: 1.1em !important;
    font-weight: 700;
  }
}

#OLClaimsContainer {
  display: grid;

  .heading3 {
    color: #16204a;
  }

  .radio {
    padding: 10px 10px 10px 10px;
    margin: 0.5em !important;
  }

  /*Li Hack the checkbox to change the color and checkmark*/
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: relative;
    left: 0;
    opacity: 0.01;
    width: 20px;
    height: 20px;
    display: none;
  }

  input[type='radio']:not(checked)+span:before {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: 2px;
    left: -8px;
    right: 5px;
    position: relative;
    //background-color: #f47e20;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #16204a;
    margin-left: 10px;

  }

  input[type='radio']:checked+span:before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 2px;
    left: -8px;
    right: 2px;
    position: relative;
    background-color: #f47e20;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px #16204a;
    margin-left: 10px;
  }

  /*Li Hack the radio to change the color and checkmark*/


}

#OLPersonalInfoContainer {
  .field {
    max-width: calc(100% - 16px);
    padding-right: 15px;
    padding-left: 20px;
  }

  .field-label {
    flex-basis: 160px;
    flex-grow: 0;
  }

  .inputCheckBoxLabel {
    font-weight: 700;
    color: #363636;
    margin-bottom: 10%;
  }

  .inputCheckBox {
    height: 15px !important;
    width: 15px !important;
  }

}

#OLLossInfoContainer {

  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  display: flex !important;
  flex-direction: column !important;
  //margin-right: 20px !important;
  //margin-left: 20px !important;
  padding: 10px 0px 5px 15px;
  flex-grow: 0;

  .heading2 {
    margin-bottom: 20px;
    padding: 5px 30px;
  }

  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 1px;
  font-size: 1.1rem;
  //text-align: left;

  .field {
    max-width: calc(100% - 16px);
    padding-right: 5px;
    padding-left: 2px;
  }

  .field-label {
    flex-basis: 150px;
    flex-grow: 0;
  }

  :first-child {
    //font-weight: bold;
    flex-grow: 0;
    width: 100%;

    .inputCheckBox {
      width: 15px !important;
      height: 15px !important;
    }
  }
}

#OLAdditionalInfoContainer {


  border-color: lightgray;
  border-style: solid;
  border-width: 0.15em;
  border-radius: 2px;

  .unfilledField {
    border-color: red;
    border-style: solid;
    border-width: 0.1em;
    border-radius: 4px;
  }



  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  //display: flex !important;
  flex-direction: column !important;
  //margin-right: 20px !important;
  //margin-left: 20px !important;
  padding: 10px 10px 5px 0px;
  flex-grow: 0;

  .heading2 {
    margin-bottom: 20px;
    padding: 5px 30px;
  }

  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 1px;
  font-size: 1.1rem;
  text-align: left;

  :first-child {
    //font-weight: bold;
    flex-grow: 4;
    width: 100%;

    .inputCheckBox {
      width: 15px !important;
      height: 15px !important;
    }
  }

  .field {
    max-width: calc(100% - 16px);
    //padding-right: 5px;
    padding-left: 2px;
  }

  .field-label {
    flex-basis: 210px;
    flex-grow: 1;
  }

}

#OLOtherPartyInfoContainer {

  border-color: lightgray;
  border-style: solid;
  border-width: 0.15em;
  border-radius: 2px;

  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  //display: flex !important;
  flex-direction: column !important;
  //margin-right: 20px !important;
  //margin-left: 20px !important;
  padding: 10px 0px 15px 0px;
  flex-grow: 0;

  .heading2 {
    margin-bottom: 20px;
    padding: 5px 30px;
  }

  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 2px;
  font-size: 1.1rem;
  text-align: left;

  :first-child {
    //font-weight: bold;
    flex-grow: 4;
    width: 100%;

    .inputCheckBox {
      width: 15px !important;
      height: 15px !important;
    }
  }

  .field {
    max-width: calc(100% - 16px);
    //padding-right: 5px;
    padding-left: 2px;
  }

  .field-label {
    flex-basis: 210px;
    flex-grow: 1;
  }
}

#ClaimDocumentUploadContainer {
  padding-top: 10px;
  display: flex !important;
  flex-direction: column !important;
  margin-right: 30px !important;

  //margin-left: 40px !important;
  .select select {
    font-size: 0.9em !important;
  }

  .select.is-medium {
    &:after {
      left: 8.05em;
      right: 0;
      top: 1.4em;
    }
  }

  .section {
    display: flex !important;
    flex-direction: column !important;
    padding: 15px 60px 50px 50px !important;

    #flexInput {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-evenly;
    }

    .input-holder {
      display: grid !important;
      grid-template-columns: 1fr 3fr !important;
      padding: 5px 0px !important;

      &#in-line {
        display: grid !important;
        grid-template-columns: 2fr 1.5fr 1fr 1.5fr !important;

        .field-label:nth-of-type(2) {
          padding: 15px 0px !important;
          margin-right: 0 !important;
          text-align: left !important;

          .heading3 {
            margin: 0 !important;
            margin-right: 15px !important;
            font-size: medium !important;
            font-weight: 650 !important;
            text-align: right !important;
          }
        }
      }

      .field-label {
        padding: 15px 0px !important;
        margin-right: 0 !important;
        text-align: left !important;
      }

      .heading3 {
        margin: 0 !important;
        font-size: medium !important;
        font-weight: 650 !important;
      }

      .field-body {
        display: flex !important;
        flex-grow: 1 !important;
      }

      .form-text {
        height: 2.5rem !important;
        width: 70% !important;
        border: none !important;
        border-radius: 5px !important;
        font-size: 1rem !important;

        &:focus {
          outline: none !important;
        }

        &#small {
          width: 80% !important;
          border: none !important;
          border-radius: 10px !important;
          font-size: 1rem !important;
          height: 2.5rem !important;
        }

        &#large {
          width: 100% !important;
          border: none !important;
          border-radius: 10px !important;
          font-size: 1rem !important;
          height: 2.5rem !important;
        }
      }
    }

    .heading2 {
      margin-top: 20px !important;
    }
  }

  .checkbox-holder {
    display: flex !important;
    flex-direction: row !important;
    padding: 30px 0px !important;

    #checkbox {
      margin-right: 20px !important;
      height: 30px !important;
      width: 30px !important;
    }

    div {
      line-height: 2.2 !important;
    }
  }

  .buttonHolder {
    display: flex !important;
    flex-direction: row !important;
    width: 50% !important;
    margin-left: 25% !important;
  }

  button {
    //replicate file upload button
    padding: 6px 1px 8px 1px !important;
    width: 165px !important;
    background-color: whitesmoke !important;
    border-radius: 4px !important;
    border: solid 1px;
    border-color: #dbdbdb !important;
    font-size: 1em;
    color: #363636;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

// checkboxes

input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
  margin-top: 15px !important;

  &:focus {
    outline: none !important;
  }
}




@media only screen and (max-width: 1024px) {
  #OLAdditionalInfoContainer {

    .field {
      max-width: calc(100% - 10px);
      //max-width: fit-content !important;
      padding-right: 1px;
      padding-left: 2px;
    }

    .field-label {
      flex-basis: 150px !important;
      flex-grow: 0 !important;
      text-align: left !important;
    }

    .is-horizontal {
      display: grid !important;
      //flex-direction: column !important;

      grid-template-columns: 1fr;

    }

    .colorDivider {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    //display: flex !important;
    //flex-direction: column !important;
    //margin-right: 20px !important;
    //margin-left: 20px !important;
    padding: 10px 0px 5px 15px;
    flex-grow: 0;

    .heading2 {
      margin-bottom: 20px;
      padding: 5px 30px;
    }

    display: grid !important;
    grid-template-columns: 1fr;
    grid-gap: 1px;
    font-size: 1.1rem;
  }

  #OLOtherPartyInfoContainer {

    border-color: lightgray;
    border-style: solid;
    border-width: 0.15em;
    border-radius: 2px;

    padding: 10px 0px 5px 15px;

    display: grid !important;
    grid-template-columns: 1fr;
    grid-gap: 1px;
    font-size: 1.1rem;

    .field {
      max-width: calc(100% - 10px);
      //max-width: fit-content !important;
      padding-right: 1px;
      padding-left: 2px;
    }

    .field-label {
      flex-basis: 150px !important;
      flex-grow: 0 !important;
      text-align: left !important;
    }

    .is-horizontal {
      display: grid !important;
      //flex-direction: column !important;

      grid-template-columns: 1fr;

    }

  }

  #OLLossInfoContainer {

    padding: 10px 0px 5px 15px;
    flex-grow: 0;


    .field {
      max-width: calc(100% - 10px);
      padding-right: 5px;
      padding-left: 2px;
    }

    .field-label {
      flex-basis: 10px;
      flex-grow: 0;
      text-align: left !important;
    }

    .is-horizontal {
      display: grid !important;
      //flex-direction: column !important;

      grid-template-columns: 1fr;

    }

    display: grid !important;
    grid-template-columns: 1fr;
    grid-gap: 1px;
    font-size: 1.1rem;
  }

  #OLPersonalInfoContainer {
    .field {
      max-width: calc(100% - 16px);
      //max-width: fit-content !important;
      padding-right: 1px;
      padding-left: 2px;
    }

    .field-label {
      flex-basis: 160px !important;
      flex-grow: 0 !important;
      text-align: left !important;
    }

    .is-horizontal {
      display: grid !important;
      //flex-direction: column !important;

      grid-template-columns: 1fr;

    }


    display: grid !important;
    grid-template-columns: 1fr;
    grid-gap: 1px;
    font-size: 1.1rem;

    .field-label {
      flex-basis: 160px !important;
      flex-grow: 0;
    }

    .inputCheckBoxLabel {
      font-weight: 700;
      color: #363636;
      margin-bottom: 10%;
    }

    .inputCheckBox {
      height: 15px !important;
      width: 15px !important;
    }

  }


}