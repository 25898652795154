.forgot {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .title-holder,
  #forgot-password-form {
    width: 500px;
    max-width: 100%;
  }
  .title-holder {
    margin-bottom: 1rem;
  }
  .button-container {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 1.5rem auto;
  }
}
