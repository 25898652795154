@import "../../../variables";

#MemDirectoryContainer,
#DirectoryContainer,
#meetingsContainer {
  .colorDivider {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  .tableSection,
  #meetingSection {
    display: flex;
    flex-direction: column;
    width: 100% !important;

    .meetingTextButtonHolder {
      flex-grow: none;
    }

    .tableContainer {
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 1rem;
      font-size: 1.5rem;
      font-weight: bold;

      .tableItem {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-weight: bold;
        align-items: center;
        // padding: 0px 30px 15px 30px;
        cursor: pointer;

        .meetingTextButton {
          border: 0;
          font-size: .9rem;
          text-align: left;
          background: transparent;
          padding: 0;
          padding-right: 10px;
          font-weight: bold;
          flex-grow: 0;
        }

        svg {
          color: $secondary;
        }

        .downloadIcon {

          //Li
          &:hover {
            color: $primary;
          }

          //margin-right: 0.5rem;

        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #MemDirectoryContainer {
    .tableSection {
      .tableContainer {
        display: flex;
        flex-direction: column;

        .table-item {
          grid-column-end: span 12;
        }
      }
    }

    .section {
      width: 100%;
    }
  }
}