//COLORS

$primary: #16204a;
$secondary: #f47e20;
$tertiary: #124a80;

$white: #ffffff;
$black: #030a1b; // dark navy
$link: #f47e20;
$lightBlue: #f0f6fc; // backgrounds
$paleBlue: #f6fafe; //table columns
$red: #dd3406; //danger
$yellow: #ffe156;
$green: #8ae9c1; //success
$aqua: #c7ecff; // horizontal section dividers

$danger: #dd3406;
$success: #8ae9c1;

$lightGrey: #f4f4f4;

// bar graph
$blue: #48b5ff;
$medBlue: #1871ad;
$darkBlue: #024470;