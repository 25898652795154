@import "../../variables";

.data-table-container {
  position: relative;
  &.is-fixed-width {
    max-width: 100%;
  }
  table {
    td {
      color: $black;
      text-align: center !important;
      border-color: $black;
      padding: 0.8rem 0.5rem !important;
      max-width: 200px;  //change to 200px from 150 for claim notification, seems good to other places
      svg {
        font-weight: normal !important;
      }
    }
    td,
    th {
      cursor: pointer;
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          font-weight: normal;
          letter-spacing: 1px;
          text-align: center !important;
          background: $tertiary;
          border-color: $primary;
          color: $white;
          font-size: 1rem;
          padding-top: 1rem !important;
          padding-bottom: 1rem !important;
        }
      }
      &:not(:last-child) {
        th {
          border-bottom: 1px solid $white;
        }
      }
    }

    tr {
      white-space: normal;
      transition: text-shadow 0.2s;
      :hover {
        text-shadow: 0.75px 0px 0px $primary;
      }
    }
    tr:nth-child(even) {
      background: $paleBlue;
    }
  }
  pre {
    padding: 0;
    background: none;
  }
}

@media screen and (max-width: 767px) {
  .data-table-container {
    position: relative;
    font-size: 0.8rem;
    &.is-fixed-width {
      max-width: 100%;
    }
    table {
      width: 100%;
      td {
        color: $black;
        text-align: center !important;
        border-color: $black;
        padding: 0.8rem 0.5rem !important;
      }
      td,
      th {
        cursor: pointer;
        &:not(.note) {
          white-space: pre-wrap;
        }
        a{
          text-decoration: none;
          color: $blue;
        }
      }
}
  }
}