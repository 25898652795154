@import "../../variables";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.smallLoadingContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  .smallLoading {
    z-index: 99;
    background: transparent;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 10vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      max-width: 35%;
      height: auto;
      animation: spin 3s infinite linear;
    }
  }
}
