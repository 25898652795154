@import "../../../variables";

.roundButton {
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 20px 30px !important;
  margin-bottom: 10px !important;
  width: 210px !important;
  border-radius: 50px !important;

  font-weight: 600 !important;

  font-size: 1.3rem !important;
  height: fit-content !important;
  text-align: center !important;
  transition: 150ms ease !important;
}
.primary {
  background-color: $secondary !important;
  color: $white !important;
  border: 2px solid $secondary !important;
  &:hover {
    background-color: darken($secondary, 5%) !important;
  }
  &.disabled {
    background-color: $secondary !important;
    opacity: 50%;
  }
}

.secondary {
  background-color: $white !important;
  color: $secondary !important;
  border: 2px solid $secondary !important;
  &:hover {
    background-color: darken($white, 5%) !important;
  }
}
