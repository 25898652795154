@import "../../../variables";

#EndorsementsPageContainer {
  display: flex !important;
  flex-direction: column !important;
  margin-right: 30px !important;
  margin-left: 40px !important;

  .select.is-medium {
    margin-bottom: 1rem;

    &:after {
      left: 8.05em;
      right: 0;
    }
  }

  .section {
    display: flex !important;
    flex-direction: column !important;
    padding: 15px 60px 50px 50px !important;

    .input-holder {
      // display: flex !important;
      // flex-direction: row !important;
      display: grid !important;
      grid-template-columns: 1fr 2fr !important;
      padding: 5px 0px !important;

      &#in-line {
        display: grid !important;
        grid-template-columns: 2fr 1.5fr 1fr 1.5fr !important;

        .field-label:nth-of-type(2) {
          padding: 15px 0px !important;
          margin-right: 0 !important;
          text-align: left !important;

          .heading3 {
            margin: 0 !important;
            margin-right: 15px !important;
            font-size: medium !important;
            font-weight: 650 !important;
            text-align: right !important;
          }
        }
      }

      .field-label {
        padding: 15px 0px !important;
        // max-width: 210px !important;
        // min-width: 210px !important;
        // flex-basis: unset !important;
        // flex-grow: unset !important;
        // flex-shrink: unset !important;
        margin-right: 0 !important;
        text-align: left !important;
        // &#in-line{
        //   max-width: 115px !important;
        //   min-width: 115px !important;
        //   .heading3{
        //     margin: 0 !important;
        //     margin-right:15px !important;
        //     font-size: medium !important;
        //     font-weight: 650 !important;
        //     text-align: right !important;
        //   }
      }

      .heading3 {
        margin: 0 !important;
        font-size: medium !important;
        font-weight: 650 !important;
      }

      .field-body {
        display: flex !important;
        flex-grow: 1 !important;
      }

      .form-text {
        height: 2.5rem !important;
        width: 70% !important;
        border: none !important;
        border-radius: 5px !important;
        font-size: 1rem !important;

        &:focus {
          outline: none !important;
        }

        &#small {
          width: 80% !important;
          border: none !important;
          border-radius: 10px !important;
          font-size: 1rem !important;
          height: 2.5rem !important;
        }

        &#large {
          width: 100% !important;
          border: none !important;
          border-radius: 10px !important;
          font-size: 1rem !important;
          height: 2.5rem !important;
        }
      }
    }

    .heading2 {
      margin-top: 20px !important;
    }
  }

  .checkbox-holder {
    display: flex !important;
    flex-direction: row !important;
    padding: 30px 0px !important;

    #checkbox {
      margin-right: 20px !important;
      height: 30px !important;
      width: 30px !important;
    }

    div {
      line-height: 2.2 !important;
    }
  }

  .buttonHolder {
    display: flex !important;
    flex-direction: row !important;
    width: 50% !important;
    margin-left: 25% !important;
  }

  input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer !important;
    margin-top: 15px !important;

    &:focus {
      outline: none !important;
    }




  }
}

// checkboxes



////// mobile
@media screen and (max-width: 767px) {
  #EndorsementsPageContainer {
    display: block !important;
    margin-left: 0px !important;

    .section {
      display: block !important;
      padding: 15px 35px 50px 10px !important;

      .input-holder {
        display: block !important;

        .form-text {
          &#small {
            width: 70% !important;
          }
        }

        .field-label {
          &#in-line {
            .heading3 {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}