@import "../../../variables";

.heading3 {
  margin: 0;
  font-size: medium;
  font-weight: 800;
  .form-star{
    color: #DD3407;
  }
}
