@import "../../../variables";

#TelematicsContainer {
  .TelematicsHeader {
    display: flex;
    justify-content: space-between;

    a {
      height: fit-content !important;
    }

    .roundButton {
      width: unset !important;
    }

    .section {
      &:first-child {
        margin-right: 50px !important;
      }

      :first-child {
        flex-grow: 1;
      }

      flex-direction: column;
      padding: 30px 0px 40px 0px;

      .heading2 {
        margin-bottom: 35px;
        padding: 5px 30px;
      }

      .BigPrimary {
        // font-size: 2rem;
        color: $primary !important;
        text-align: center;
        //font-weight: 800;
        padding: 5px 25px;

        p {
          margin: 0;
        }
      }
    }
  }

  .DriverScore {
    //margin-top: 3rem;

    .section {
      .textButton {
        border-left: none;
      }
    }
  }

  #iconTooltip {
    font-weight: bold;
  }

  .TEDetail2Colum {
    padding-Top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5em;

    .section {


      :first-child {
        flex-grow: 0 !important;
      }
    }
  }
}

////// MOBILE STYLING
@media screen and (max-width: 1024px) {
  #TelematicsContainer {
    .TelematicsHeader {
      display: inline-block !important;

      .section {
        .BigOrange {
          font-size: 2rem;
        }
      }

      .roundButton {
        width: unset !important;
      }
    }

    .TEDetail2Colum {
      display: inline-block;
    }
  }
}