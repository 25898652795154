.section {
  display: flex;
  :first-child {
    flex-grow: 1;
  }
}


@media only screen and (max-width: 767px) and (max-device-width: 1024px) {
  #MemberKioskPage{
    word-break: break-word;
  }
}