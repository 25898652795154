@import "../../../variables";

#RenewalDocContainer {
    .headerContainer {
        display: flex;
        align-items: center;

        :first-child {
            flex: 1;
        }
    }

    td {
        word-wrap: break-word
    }


}

.RenewalAndUpload {
    display: grid;
    grid-template-columns: 1fr 1fr !important;

    :nth-child(2) {
        text-align: end;
    }
}

.RenewalDocSection {

    display: flex;
    flex-direction: column;

    :first-child {
        flex-grow: 1;
    }


}



.renewalDesc {
    display: flex;
    flex-direction: column;
    font-weight: bold;

}

.renewalDescIndividual {
    margin: 5px 0 5px 0px;
}

.policyTerm {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
}

#RenewalDocumentUploadContainer {

    display: flex !important;
    flex-direction: column !important;
    margin-right: 30px !important;
    margin-left: 40px !important;

    .select select {
        font-size: 0.9em !important;
    }

    .select.is-medium {
        &:after {
            left: 8.05em;
            right: 0;
            top: 1.4em;
        }
    }

    .section {
        display: flex !important;
        flex-direction: column !important;
        padding: 15px 60px 1px 50px !important;

        #flexInput {
            display: flex !important;
            flex-direction: row !important;
            justify-content: space-evenly;
        }

        .input-holder {
            display: grid !important;
            grid-template-columns: 1fr 3fr !important;
            padding: 5px 0px !important;

            &#in-line {
                display: grid !important;
                grid-template-columns: 2fr 1.5fr 1fr 1.5fr !important;

                .field-label:nth-of-type(2) {
                    padding: 15px 0px !important;
                    margin-right: 0 !important;
                    text-align: left !important;

                    .heading3 {
                        margin: 0 !important;
                        margin-right: 15px !important;
                        font-size: medium !important;
                        font-weight: 650 !important;
                        text-align: right !important;
                    }
                }
            }

            .field-label {
                padding: 15px 0px !important;
                margin-right: 0 !important;
                text-align: left !important;
            }

            .heading3 {
                margin: 0 !important;
                font-size: medium !important;
                font-weight: 650 !important;
            }

            .field-body {
                display: flex !important;
                flex-grow: 1 !important;
            }

            .form-text {
                height: 2.5rem !important;
                width: 70% !important;
                border: none !important;
                border-radius: 5px !important;
                font-size: 1rem !important;

                &:focus {
                    outline: none !important;
                }

                &#small {
                    width: 80% !important;
                    border: none !important;
                    border-radius: 10px !important;
                    font-size: 1rem !important;
                    height: 2.5rem !important;
                }

                &#large {
                    width: 100% !important;
                    border: none !important;
                    border-radius: 10px !important;
                    font-size: 1rem !important;
                    height: 2.5rem !important;
                }
            }
        }

        .heading2 {
            margin-top: 20px !important;
        }
    }

    .checkbox-holder {
        display: flex !important;
        flex-direction: row !important;
        padding: 30px 0px !important;

        #checkbox {
            margin-right: 20px !important;
            height: 30px !important;
            width: 30px !important;
        }

        div {
            line-height: 2.2 !important;
        }
    }

    .buttonHolder {
        display: flex !important;
        flex-direction: row !important;
        width: 40% !important;
        margin-left: 25% !important;

        .roundButton {
            //padding-left: 5px !important;
            margin-left: 0.5%;
        }

    }




}




//@media only screen and (max-width: 1280px) and (max-device-width: 1280px) {

@media screen and (max-width: 1280px) {

    .RenewalAndUpload {
        display: flex !important;
        flex-direction: column;
    }



    .RenewalDocSection {

        display: flex;
        flex-direction: column;

        :first-child {
            flex-grow: 1;
        }


    }

    .policyTerm {
        display: flex;
        flex-direction: column;
    }



    .renewalDesc {
        display: flex;
        flex-direction: column;
        margin-right: 25%;
    }

    .renewalDescIndividual {
        margin: 5px 0px 5px 0px;
    }

    #RenewalDocumentUploadContainer {

        display: flex !important;
        flex-direction: column !important;
        margin-right: 0px !important;
        margin-left: 0px !important;


        .select select {
            font-size: 0.9em !important;
        }

        .select.is-medium {
            &:after {
                left: 8.05em;
                right: 0;
                top: 1.4em;
            }
        }

        .section {
            display: flex !important;
            flex-direction: row !important;
            //padding: 15px 60px 50px 50px !important;
            padding: 5px 10px 0px 0px !important;



            #flexInput {
                display: flex !important;
                flex-direction: column !important;
                justify-content: space-evenly;
            }


            .input-holder {
                display: flex !important;
                //grid-template-columns: 1fr !important;
                flex-direction: column;
                padding: 0px 0px 0px 0px !important;




                &#in-line {
                    display: grid !important;
                    //grid-template-columns: 2fr 1.5fr 1fr 1.5fr !important;
                    grid-template-columns: 2fr !important;

                    .field-label:nth-of-type(2) {
                        padding: 15px 0px !important;
                        margin-right: 0 !important;
                        text-align: left !important;

                        .heading3 {
                            margin: 0 !important;
                            margin-right: 15px !important;
                            font-size: medium !important;
                            font-weight: 650 !important;
                            text-align: right !important;
                        }
                    }
                }

                .field-label {
                    padding: 15px 0px !important;
                    margin-right: 0 !important;
                    text-align: left !important;
                }

                .heading3 {
                    margin: 0 !important;
                    font-size: medium !important;
                    font-weight: 650 !important;
                }

                .field-body {
                    display: flex !important;
                    flex-grow: 1 !important;


                }

                .form-text {
                    height: 2.5rem !important;
                    width: 70% !important;
                    border: none !important;
                    border-radius: 5px !important;
                    font-size: 1rem !important;

                    &:focus {
                        outline: none !important;
                    }

                    &#small {
                        width: 80% !important;
                        border: none !important;
                        border-radius: 10px !important;
                        font-size: 1rem !important;
                        height: 2.5rem !important;
                    }

                    &#large {
                        width: 100% !important;
                        border: none !important;
                        border-radius: 10px !important;
                        font-size: 1rem !important;
                        height: 2.5rem !important;
                    }
                }
            }


            .heading2 {
                margin-top: 20px !important;
            }
        }

        .buttonHolder {

            display: flex !important;
            flex-direction: column !important;

            //width: 100% !important;
            //margin-left: 30% !important;

            .roundButton {
                //padding-left: 5px !important;
                margin-left: 0.5%;
            }
        }




    }

    .buttonHolder {
        display: flex !important;
        flex-direction: column !important;
        width: 50% !important;
        //margin-left: 25% !important;

        .roundButton {
            //padding-left: 5px !important;
            margin-left: 0.5%;
        }
    }





    #RenewalDocContainer {
        .summaryHeader {
            flex-direction: column;
        }

        .summaryReport {
            display: flex;
            flex-direction: column;

            .section {
                display: flex;
                flex-direction: column;
                width: auto;

                .three-column-grid {
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }
        }


        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            width: 100%;
            display: table;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            margin: 0 0rem 1rem 0;

        }

        // tr:nth-child(odd) {
        //   background: #ccc;
        // }

        tr:nth-child(even) {
            background: $paleBlue !important;
        }

        tr:nth-child(odd) {
            background: $lightGrey !important;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            //position: relative;
            min-width: 150%;
            //padding-left: 50%;
            //padding-right: 50%;
            //margin-right: 20px;
            //word-wrap: break-word !important;
        }

        td:before {
            /* Now like a table header */
            // position: absolute;
            /* Top/left values mimic padding */
            top: 0;
            left: 1px;
            width: 0%;
            //padding-right: 0px;
            font-weight: bolder;
            white-space: nowrap;
            float: left;
        }

        .data-table-container table td:not(.note),
        .data-table-container table th:not(.note) {
            white-space: pre-wrap;
        }

        /*
    Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
    */

        td:nth-of-type(1):before {
            content: "Document:";
        }

        td:nth-of-type(2):before {
            content: "Category:";
        }

        td:nth-of-type(3):before {
            content: "Date:";
        }

        td:nth-of-type(4):before {
            content: "Submitter:";
        }

        td:nth-of-type(5):before {
            content: "Download:";
        }

        // td:nth-of-type(5):before {
        //     content: "Exp. Date:";
        // }
    }
}