@import "../../variables";

#claimsReportContainer {
  .claimsHeader {
    display: flex !important;
    .buttonContainer{
      display: flex;
      flex-direction: column;
      .roundButton{
        width: unset !important;
      }
    }
    :first-child {
      flex-grow: 1 !important;
    }
  }
  .section:nth-of-type(2){
    display: flex;
    flex-direction: row;
    background-color: $tertiary !important;
    color: white;
    font-weight: bold;
    align-items: center;
    svg{
      margin-right: 1em;
      padding-left: 0.4em;
      width: unset !important;
    }
  }
  .section{
    display: flex;
    flex-direction: column;
    .sectionHeader{
      display: flex;
      font-size: 26px;
      font-weight: bold;
      .orangeNumber{
        color: $secondary;
        flex-grow: 0;
        margin-right: 0.5rem;
      }
    }
    .sectionContentWrapper{
      padding-left: 1.8rem;
      .boldText{
        font-weight: bold;
      }
      .gridWrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        .right-column{
          .displayNone{
            visibility: hidden;
          }
        }
      }
    }
  }
  .bottomLinksWrapper{
    font-weight: bold;
    align-items: center;
    .section:nth-of-type(2){
      display: flex;
      flex-direction: row;
      background-color: #f0f6fc !important;
      color: inherit;
      svg{
        margin-right: 1em;
        padding-left: 0.4em;
      }
    }
    .section{
      display: flex;
      flex-direction: row;
      .textButton{
        font-size: 26px;
        border: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #claimsReportContainer {
    font-size: 0.75em;
    .claimsHeader{
      display: inline-block !important;
    }
    .section:nth-of-type(2){
      display: flex !important;
    }
    .section {
        // display: inline-block !important;
        width: 85%;
        display: flex !important;
        .sectionContentWrapper{
          .gridWrapper{
            display: inherit;
          }
        }
    }
  }
}

